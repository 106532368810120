<template>
  <div class="has-background-white-bis height100vh">
    <NavBarApp v-show="!isLoading" />
    <div class="container mt-2 mb-5" v-show="!isLoading">
      <Error404 v-if="is404" />
      <slot v-else></slot>
    </div>
    <b-loading is-full-page :active.sync="isLoading" />
  </div>
</template>

<script>
import NavBarApp from "@/components/NavBarApp";
import Error404 from "@/modules/error/pages/404.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    NavBarApp,
    Error404,
  },
  computed: {
    ...mapGetters({
      isLoading: "app/loading_page",
      is404: "app/is404"
    }),
  },
};
</script>
